<template>
  <v-row>
    <v-col md="6" cols="12">
      <v-card class="border-radius-xl card-shadow">
        <div class="px-6 py-6 text-center">
          <h1 class="text-gradient text-success font-weight-bolder text-h1">
            <span>{{ totalPrice }}</span>
          </h1>
          <h6 class="mb-0 text-h6 font-weight-bolder text-typo">Нийт Орлого</h6>
          <p class="opacity-8 mb-0 text-sm text-typo"></p>
        </div>
      </v-card>
    </v-col>
    <v-col md="6" cols="12">
      <v-card class="border-radius-xl card-shadow">
        <div class="px-6 py-6 text-center">
          <h1 class="text-gradient text-success font-weight-bolder text-h1">
            <span>{{ totalQuantity }}</span>
          </h1>
          <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
            Нийт Зарагдсан
          </h6>
          <p class="opacity-8 mb-0 text-sm text-typo"></p>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
/*eslint-disable*/
import Chart from "chart.js/auto";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    totalPrice: {
      type: String,
      default: 0,
    },
    totalQuantity: {
      type: Number,
      default: 0,
    },
  },
};
</script>
