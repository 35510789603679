<template>
  <v-card>
    <div class="px-4 py-4">
      <h6 class="text-h6 text-typo font-weight-bold">Нийт Орлогын Мэдээлэл</h6>

      <div class="card-padding">
        <div class="chart">
          <canvas id="revenueChart" class="chart-canvas"></canvas>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
/*eslint-disable*/
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
Chart.register(ChartDataLabels);
export default {
  components: {},
  data() {
    return {
      myChart: null,
    };
  },
  computed: {
    ...sync("*"),
  },
  methods: {
    find(chartLabels, chartNumbers) {
      var xChart = document.getElementById("revenueChart");
      if (xChart) {
        this.myChart = new Chart(xChart.getContext("2d"), {
          type: "bar",
          data: {
            labels: chartLabels,
            datasets: [
              // {
              //   label: "Watts",
              //   tension: 0.4,
              //   borderWidth: 0,
              //   borderRadius: 4,
              //   borderSkipped: false,
              //   backgroundColor: "#3A416F",
              //   data: this.averageScores,
              //   maxBarThickness: 6,
              //   datalabels: {
              //     color: "green",
              //     align: "end",
              //     anchor: "end",
              //   },
              // },
              {
                label: "Сонгосон тоо",
                tension: 0.4,
                borderWidth: 0,
                borderRadius: 4,
                borderSkipped: false,
                backgroundColor: "red",
                data: chartNumbers,
                maxBarThickness: 6,
                datalabels: {
                  color: "red",
                  align: "end",
                  anchor: "end",
                },
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                color: "#36A2EB",
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 15,
                  color: "#9ca2b7",
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#9ca2b7",
                },
              },
            },
          },
        });
      }
    },
  },
  created() {},
  props: {
    products: {
      required: true,
      type: Array,
    },
  },
  watch: {
    products: {
      handler(val) {
        var chartLabels = [];
        var chartNumbers = [];
        for (var product of val) {
          chartLabels.push(product.name);
          chartNumbers.push(product.qty * product.price2);
        }
        this.myChart.destroy();
        this.find(chartLabels, chartNumbers);
      },
      deep: true,
    },
  },
  mounted() {
    var chartLabels = [];
    var chartNumbers = [];
    for (var product of this.products) {
      chartLabels.push(product.name);
      chartNumbers.push(product.qty * product.price2);
    }
    this.find(chartLabels, chartNumbers);
  },
};
</script>
